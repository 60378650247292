* {
  box-sizing: border-box;
}

body {
  color: #fff;
  min-height: 100vh;
  background-color: #080808;
  background-image: url("dots.d3caa6d4.png");
  background-repeat: repeat;
}

html, body {
  margin: 0;
  padding: 0;
}

.designer {
  display: flex;
}

.container {
  aspect-ratio: 1 / 1;
  max-width: 100vw;
  max-height: 100vh;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 1rem;
  display: flex;
}

.playground {
  color: #000;
  background: #fff;
  border-radius: 8px;
}

.default-menu {
  padding: 2rem 3rem;
  font-family: Finlandica, sans-serif;
}

.default-menu h3 {
  letter-spacing: 3px;
  font-weight: 500;
}

.default-menu .menu-item {
  letter-spacing: 1px;
  cursor: pointer;
  border-left: 1px solid #0000;
  padding: .25rem 0 .25rem 1rem;
  transition: all .2s;
  transform: translateX(-1rem);
}

.default-menu .menu-item.active {
  border-left-color: #333;
  transform: translateX(0);
}

.component-wrapper {
  position: relative;
}

.component-wrapper .marker {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: Finlandica, sans-serif;
  display: flex;
  position: absolute;
}

.component-wrapper .marker.left {
  right: 100%;
}

.component-wrapper .marker.right {
  left: 100%;
}

.component-wrapper .marker.top {
  bottom: 100%;
}

.component-wrapper .marker.bottom {
  top: 100%;
}

.component-wrapper .marker-path {
  fill: none;
  stroke: #fff;
  stroke-width: 1px;
  position: absolute;
}

.component-wrapper .marker-path.left, .component-wrapper .marker-path.right {
  width: 10rem;
}

.component-wrapper .marker-path.top, .component-wrapper .marker-path.bottom {
  height: 5rem;
}

.component-wrapper .marker-path.left {
  left: 0;
}

.component-wrapper .marker-path.right {
  right: 0;
}

.component-wrapper .marker-path.top {
  top: 0;
}

.component-wrapper .marker-path.bottom {
  bottom: 0;
}

.component-wrapper .wrapper-padding {
  padding: 5rem 10rem;
  position: relative;
}

.demo-layout {
  min-width: 400px;
  min-height: 300px;
  background-color: #111;
  border-radius: 8px;
  grid-template-rows: 28px 1fr;
  grid-template-columns: 100px 1fr;
  display: grid;
  overflow: hidden;
}

.demo-layout .window-bar {
  user-select: none;
  background-color: #222;
  grid-column: span 2;
  align-items: center;
  padding: 4px;
  display: flex;
}

.demo-layout .window-bar .resize, .demo-layout .window-bar .close, .demo-layout .window-bar .minimize {
  height: 8px;
  width: 8px;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 8px;
  display: inline-block;
}

.demo-layout .window-bar .resize:hover, .demo-layout .window-bar .close:hover, .demo-layout .window-bar .minimize:hover {
  filter: contrast(70%);
}

.demo-layout .window-bar .resize:active, .demo-layout .window-bar .close:active, .demo-layout .window-bar .minimize:active {
  filter: contrast(50%);
}

.demo-layout .window-bar .close {
  background-color: red;
}

.demo-layout .window-bar .resize {
  background-color: orange;
}

.demo-layout .window-bar .minimize {
  background-color: #0f0;
}

.demo-layout .window-bar h3 {
  text-align: center;
  color: #888;
  flex-grow: 1;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
}

.demo-layout nav {
  height: 100%;
  width: 100px;
  background-color: #181818;
}

.demo-layout main {
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
}

button {
  padding: calc(var(--size, 1) * .25rem) calc(var(--size, 1) * .75rem);
  font-size: calc(var(--size, 1) * 1rem);
  border-radius: calc(var(--size, 1) * .5rem);
  border: 1px solid var(--color, #fff);
  cursor: pointer;
  display: inline-block;
}

button.solid {
  background: var(--color, #fff);
}

button.outlined {
  color: var(--color, #fff);
  background: none;
}

button.link {
  color: var(--color, #fff);
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
}

button:hover {
  filter: contrast(70%);
}

button:active {
  filter: contrast(50%);
}

/*# sourceMappingURL=index.8e02a01a.css.map */
