.designer {
    display: flex;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: 1rem;
    margin: auto;
    aspect-ratio: 1 / 1;
    max-width: 100vw;
    max-height: 100vh;
}

.playground {
    background: #fff;
    border-radius: 8px;
    color: #000;
}

.default-menu {
    padding: 2rem 3rem;
    font-family: 'Finlandica', sans-serif;

    h3 {
        letter-spacing: 3px;
        font-weight: 500;
    }

    .menu-item {
        letter-spacing: 1px;
        cursor: pointer;
        transition: all 0.2s;

        transform: translateX(-1rem);
        padding: .25rem 0 .25rem  1rem;
        border-left: 1px solid transparent;

        &.active {
            transform: translateX(0rem);
            border-left-color: #333;
        }
    }
}

.component-wrapper {
    position: relative;
    
    .marker {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: 'Finlandica', sans-serif;
        gap: 4px;  

        &.left {
            right: 100%;
        }
        &.right {
            left: 100%;
        }
        &.top {
            bottom: 100%;
        }
        &.bottom {
            top: 100%;
        }
    }

    .marker-path {
        position: absolute;
        fill:none;
        stroke:#fff;
        stroke-width: 1;


        &.left, &.right {
            width: 10rem;
        }
        &.top, &.bottom {
            height: 5rem;
        }

        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
        &.top {
            top: 0;
        }
        &.bottom {
            bottom: 0;
        }
    }

    .wrapper-padding{
        padding: 5rem 10rem;
        position: relative;
    }
}