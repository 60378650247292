
* {
    box-sizing: border-box;
}

body {
    background-color: #080808;
    background-image: url(../asset/dots.png);
    background-repeat: repeat;

    color: #fff;
    min-height: 100vh;
}

html, body {
    margin: 0;
    padding: 0;
}