

/*
button {
}
*/

.demo-layout{
    min-width: 400px;
    min-height: 300px;
    border-radius:8px;
    background-color: #111;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: 28px 1fr;

    .window-bar{
        padding: 4px;
        background-color: #222;
        grid-column: span 2;
        display: flex;
        align-items: center;
        user-select: none;

        .resize, .close, .minimize {
            height: 8px;
            width: 8px;
            display: inline-block;
            margin-left: 8px;
            border-radius:8px;
            cursor: pointer;

            &:hover {
                filter: contrast(70%);
            }

            &:active {
                filter: contrast(50%);
            }
        }
    
        .close {
            background-color: red;
        }
    
        .resize {
            background-color:orange;
        }
    
        .minimize {
            background-color:lime;
        }

        h3 {
            margin: 0;
            display: inline-block;
            flex-grow: 1;

            font-size: 12px;
            text-align: center;
            font-weight: 400;

            color: #888;
        }
    }

    nav {
        height: 100%;
        width: 100px;

        background-color: #181818;
    }

    main {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 14px;
    }
}