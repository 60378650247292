

button {
    display: inline-block;
    
    padding: calc(var(--size, 1) * 0.25rem) calc(var(--size, 1) * 0.75rem);
    font-size: calc(var(--size, 1) * 1rem);

    border-radius: calc(var(--size, 1) * 0.5rem);
    border: 1px solid var(--color, #fff);
    cursor: pointer;

    &.solid {
        background: var(--color, #fff);
    }

    &.outlined {
        background: transparent;
        color: var(--color, #fff);
    }

    &.link {
        color: var(--color, #fff);
        text-decoration: underline;
        background: transparent;
        border: none;
        padding: 0;
    }
    
    &:hover {
        filter: contrast(70%);
    }

    &:active {
        filter: contrast(50%);
    }
}